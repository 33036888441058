'
<template>
  <div class="form">
    <form>
      <h3>{{ $t("sign_company.sign_up") }}</h3>
      <div class="form-group">
        <label>{{ $t("sign_company.company_name") }}</label>
        <input type="text" class="form-control form-control-lg"/>
      </div>

      <div class="form-group">
        <label>{{ $t("sign_company.email") }}</label>
        <input type="email" class="form-control form-control-lg"/>
      </div>

      <div class="form-group">
        <label>{{ $t("sign_company.password") }}</label>
        <input type="password" class="form-control form-control-lg"/>
      </div>

      <button type="submit" class="btn btn-dark btn-lg btn-block">{{ $t("sign_company.sign_up") }}</button>

      <p class="forgot-password text-right">
        {{ $t("sign_company.already_registered") }}
        <router-link :to="{name: 'social.loginpage'}">{{ $t("sign_company.sign_in") }}</router-link>
      </p>
    </form>
  </div>
</template>

<script>

import {socialvue} from '@/config/pluginInit';

export default {
  name: 'SignUpCompanyPage',
  data() {
    return {}
  },
  mounted() {
    socialvue.index()
  }
}
</script>

<style>
@import url("../../assets/css/intimlite.css");
</style>